import { useRouteData } from 'context/RouteDataProvider';
import { getExperiment } from 'services/experiments';
import { isExistingMarket } from 'utils/market';
import type { MarketId } from 'modules/market';

const EXPERIMENT = 'StickyVin';
const EXPERIMENT_MARKETS: MarketId[] = ['romania', 'poland', 'france', 'britain', 'italy'];

const useStickyVinExperiment = () => {
  const { market } = useRouteData();

  const experimentMarket = isExistingMarket({
    market,
    markets: EXPERIMENT_MARKETS,
  });

  return {
    enabled: getExperiment(EXPERIMENT) === 'v1' && experimentMarket,
  };
};

export { useStickyVinExperiment };

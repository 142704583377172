import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { useDebounce, useIntersection } from 'react-use';
import { SupportSection } from 'modules/support';
import { FeaturesSection, FeatureChecklist } from 'modules/features';
import { BusinessPromoSection } from 'modules/business/components';
import { useRouteData } from 'context/RouteDataProvider';
import { isExistingMarket } from 'utils/market';
import { IdentifierForm } from 'components/common/IdentifierForm';
import { CompactCallToActionSection, UserReviewsSection } from 'modules/shared';
import { useIdentifierPopup } from 'modules/identifier';
import { HorizontalTrustBanner } from '../common/HorizontalTrustBanner';
import { BlogSection } from '../common/BlogSection';
import { StepsSection } from '../common/StepsSection';
import { Hero, type HeroProps } from '../common/Hero';
import { FeaturesCards } from './FeaturesCards';
import { MarketLeadersSection } from './MarketLeadersSection';
import { StatFactsIllustration } from './StatFactsIlustration';
import { useStickyVinExperiment } from './hooks';
import styles from './HomePage.module.scss';

type UseIdentifierPopupVisibilityProps = {
  footerRef: React.RefObject<HTMLDivElement>;
  identifierFormRef: React.RefObject<HTMLFormElement>;
};

const IDENTIFIER_CTA_CLASSNAME = 'a-b-stickyvin-button-hero';

const useStatisticalFacts = () => {
  const { market, locale } = useRouteData();

  const experimentEnabled = isExistingMarket({
    market,
    markets: [{ britain: 'en-GB' }],
    locale,
  });

  return experimentEnabled;
};

const Page = dynamic(() => import('components/common/Page'), { ssr: false });

const HomePage = () => {
  const { t } = useTranslation(['home', 'common']);
  const showsStatisticalFacts = useStatisticalFacts();
  const { enabled: stickyVinExperimentEnabled } = useStickyVinExperiment();
  const featuresSectionRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const identifierFormRef = useRef<HTMLFormElement>(null);

  const heroLeftContent: HeroProps['leftContent'] = showsStatisticalFacts
    ? {
        title: <span className="text-h-l flex lg:text-h-xl">{t('statisticalFacts.title')}</span>,
        subtitle: t('statisticalFacts.subtitle'),
        additionalContent: <FeatureChecklist featuresSectionRef={featuresSectionRef} />,
        identifier: (
          <IdentifierForm
            surroundingBackground="mediumDark"
            continueWithoutVinCtaLabel={t('statisticalFacts.noVinCta')}
            continueCtaClassName={IDENTIFIER_CTA_CLASSNAME}
            ref={identifierFormRef}
          />
        ),
      }
    : {
        title: t('landing.seoTitle'),
        subtitle: t('landing.subtitleVin'),
        additionalContent: <FeatureChecklist featuresSectionRef={featuresSectionRef} />,
      };

  const heroRightContent: HeroProps['rightContent'] = showsStatisticalFacts
    ? { illustration: <StatFactsIllustration />, illustrationClassName: 'm-auto' }
    : {
        illustration: <FeaturesCards />,
        illustrationClassName: 'm-auto',
      };

  useIdentifierPopupVisibility({ footerRef, identifierFormRef });

  return (
    <Page
      title={t('common:seo.title')}
      description={t('common:seo.description')}
      breadcrumbs={false}
      footerRef={footerRef}
    >
      <Hero
        color="colorBlue100"
        leftContent={heroLeftContent}
        rightContent={heroRightContent}
        identifierFormClassName={IDENTIFIER_CTA_CLASSNAME}
        identifierFormRef={identifierFormRef}
      />

      <HorizontalTrustBanner />

      <FeaturesSection ref={featuresSectionRef} />

      <StepsSection color="colorBlue50" subtitleShown />

      <UserReviewsSection />

      {!stickyVinExperimentEnabled && <CompactCallToActionSection />}

      <MarketLeadersSection />

      <BusinessPromoSection />

      <SupportSection containerClassName={styles.supportContainer} />

      <BlogSection />
    </Page>
  );
};

function useIdentifierPopupVisibility({
  footerRef,
  identifierFormRef,
}: UseIdentifierPopupVisibilityProps) {
  const { enabled: stickyVinExperimentEnabled } = useStickyVinExperiment();
  const { show, hide } = useIdentifierPopup();

  const footerIntersection = useIntersection(footerRef, {
    threshold: 0,
  });
  const identifierFormIntersection = useIntersection(identifierFormRef, {
    threshold: 0.2,
  });

  useDebounce(
    () => {
      if (!stickyVinExperimentEnabled || !identifierFormIntersection || !footerIntersection) {
        return;
      }

      const footerInView = footerIntersection?.isIntersecting;
      const identifierFormInView = identifierFormIntersection?.isIntersecting;

      if (identifierFormInView || footerInView) {
        hide();
        return;
      }

      show();
    },
    100,
    [
      stickyVinExperimentEnabled,
      footerRef,
      footerIntersection,
      identifierFormIntersection,
      hide,
      show,
    ],
  );
}

export { HomePage };
